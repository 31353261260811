import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IResume, IResumeJSON } from '../../models/Resume';
import { IUser } from '../../models/User';
import { TemplateType } from '../PDFProps';
import {
  BulletPoints,
  createStyles,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
  ResumePDF,
} from '../ResumePDF';

import previewPng from './preview-stylish.png';
import previewWebP from './preview-stylish.webp';

export {
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
};

const specificStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
) =>
  StyleSheet.create({
    header: {
      fontSize: 28,
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: 5,
      fontFamily: headerFont,
    },
    subHeader: {
      fontSize: 12,
      textAlign: 'center',
      color: '#666',
      marginBottom: 20,
      fontFamily: textFont,
    },
    sectionTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#333',
      marginBottom: 5,
      textTransform: 'uppercase',
      letterSpacing: 1.5,
      fontFamily: headerFont,
    },
    subSection: {
      marginBottom: 20,
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: '#E4E4E4',
    },
    text: {
      fontSize: 10,
      color: '#666',
      marginBottom: 5,
      fontFamily: textFont,
    },
    bulletPointSymbol: {
      width: 10,
      fontSize: 10,
      marginRight: 5,
      marginTop: 2,
      color: '#333',
    },
    bulletPointText: {
      flex: 1,
      fontSize: 10,
      color: '#666',
      fontFamily: textFont,
    },
    section: {
      marginBottom: 20,
    },
    shadedBox: {
      padding: 10,
      borderRadius: 5,
      backgroundColor: '#f9f9f9',
      marginBottom: 10,
    },
    contactText: {
      fontSize: 10,
      color: '#333',
      marginBottom: 5,
      fontFamily: textFont,
    },
    contactLink: {
      fontSize: 10,
      color: primaryColor,
      textDecoration: 'underline',
      marginBottom: 5,
      fontFamily: textFont,
    },
    profileText: {
      fontSize: 10,
      color: '#666',
      lineHeight: 1.5,
      marginBottom: 10,
      fontFamily: textFont,
    },
    jobTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#333',
      marginBottom: 2,
      fontFamily: headerFont,
    },
    companyName: {
      fontSize: 10,
      color: '#333',
      fontFamily: textFont,
    },
    jobDate: {
      fontSize: 10,
      color: '#666',
      marginBottom: 5,
      fontFamily: textFont,
    },
    divider: {
      borderBottomWidth: 1,
      borderBottomColor: '#E4E4E4',
      marginBottom: 10,
      marginTop: 10,
    },
  });

export const ResumeStylishPDF: React.FC<{
  resumeId: IResume['id'];
  resume: IResumeJSON;
  user: IUser;
  language: string;
  title?: string;
  primaryColor?: string;
  pageSize?: string;
  headerFont?: string;
  textFont?: string;
}> = ({
  resume,
  user,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const { t } = useTranslation(undefined, { lng: language });

  const styles = useMemo(
    () => ({
      ...createStyles(primaryColor || '#333', headerFont, textFont),
      ...specificStyles(primaryColor || '#333', headerFont, textFont),
    }),
    [primaryColor, headerFont, textFont],
  );

  return (
    <ResumePDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <Text style={styles.header}>
        {user.profile?.firstName} {user.profile?.lastName}
      </Text>
      <Text style={styles.subHeader}>{title}</Text>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('resume.contact')}</Text>
        <Text style={styles.contactText}>{user.profile?.phone}</Text>
        <Text style={styles.contactText}>{user.profile?.address}</Text>
        <Link style={styles.contactLink}>{user.profile?.displayEmail}</Link>
        <Link style={styles.contactLink}>{user.profile?.website}</Link>
      </View>

      <View style={styles.divider}></View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('resume.introduction')}</Text>
        <Text style={styles.profileText}>{resume.intro}</Text>
      </View>

      <View style={styles.divider}></View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('resume.skills.title')}</Text>
        <BulletPoints points={resume.skills} styles={styles} />
      </View>

      <View style={styles.divider}></View>

      {Array.isArray(resume.educations) && resume.educations.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{t('resume.education.title')}</Text>
          {resume.educations.map((edu, index) => (
            <View key={index} style={styles.subSection}>
              <Text style={styles.jobTitle}>
                {edu.degree} • {edu.fieldOfStudy}
              </Text>
              <Text style={styles.companyName}>{edu.schoolName}</Text>
              <Text style={styles.jobDate}>
                {edu.start} - {edu.end}
              </Text>
              <BulletPoints points={edu.notes} styles={styles} />
            </View>
          ))}
        </View>
      )}

      <View style={styles.divider}></View>

      {Array.isArray(resume.experiences) && resume.experiences.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            {t('resume.experience.title')}
          </Text>
          {resume.experiences.map((job, index) => (
            <View key={index} style={styles.subSection} wrap={false}>
              <Text style={styles.jobTitle}>{job.title}</Text>
              <Text style={styles.companyName}>
                {job.company.name}
                {job.company.location && ` (${job.company.location})`}
              </Text>
              <Text style={styles.jobDate}>
                {job.start} - {job.end || t('timeline.present')}
              </Text>
              <BulletPoints points={job.summary} styles={styles} />
            </View>
          ))}
        </View>
      )}

      <View style={styles.divider}></View>

      {user.profile?.languages && user.profile.languages.length > 0 && (
        <View style={styles.section} wrap={false}>
          <Text style={styles.sectionTitle}>{t('resume.languages')}</Text>
          <BulletPoints
            points={user.profile.languages.map((lang) => lang.name)}
            styles={styles}
          />
        </View>
      )}
    </ResumePDF>
  );
};

export const resumeTemplateStylish: TemplateType = {
  label: 'Stylish',
  component: ResumeStylishPDF,
  preview: previewWebP,
  previewFallback: previewPng,
};
