import {
  ActionIcon,
  Button,
  Container,
  Group,
  Paper,
  rem,
  Stack,
  Text,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck, IconPencil, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IResumeWithSuggestion } from '../../models/Resume';
import { useUpdateResumeJsonMutation } from '../../queries/ResumeQueries';
import { SubscribeCTAOverlay } from '../SubscribeCTAOverlay/SubscribeCTAOverlay';

const SHOWN_PARAGRAPHS = 3;

const CoverLetterParagraph: React.FC<{ text: string; hidden: boolean }> = ({
  text,
  hidden,
}) => (
  <div style={{ position: 'relative' }}>
    <Text
      mb="1rem"
      style={{ whiteSpace: 'pre-wrap', userSelect: hidden ? 'none' : 'auto' }}
    >
      {text}
    </Text>
  </div>
);

export const CoverLetterView: React.FC<{
  resume: IResumeWithSuggestion;
  hidden: boolean;
}> = ({ resume, hidden }) => {
  const coverLetter = resume.suggestion?.coverLetter;

  const { t } = useTranslation();
  const paragraphs = coverLetter?.split('\n\n') ?? [
    t('resume.coverLetter.notAvailable'),
  ];
  const shownParagraphs = hidden
    ? paragraphs.slice(0, SHOWN_PARAGRAPHS)
    : paragraphs;
  const hiddenParagraphs = hidden ? paragraphs.slice(SHOWN_PARAGRAPHS) : [];

  const [isEditing, setIsEditing] = useState(false);
  const form = useForm({
    initialValues: {
      coverLetter: coverLetter ?? '',
    },
    validate: {
      coverLetter: (value) =>
        !value ? t('resume.coverLetter.coverLetterRequired') : null,
    },
  });

  const updateResumeMutation = useUpdateResumeJsonMutation(resume.id);

  const handleSave = () => {
    if (form.isValid()) {
      const updatedResumeJson = {
        ...resume.suggestion,
        coverLetter: form.values.coverLetter,
      };
      updateResumeMutation.mutate(updatedResumeJson, {
        onSuccess: () => {
          setIsEditing(false);
        },
      });
    }
  };

  return (
    <Container size="md">
      <Stack gap="xl">
        <Group>
          <Title order={3}>{t('view.coverLetterTitle')}</Title>
          <ActionIcon
            variant="subtle"
            size="sm"
            onClick={() => setIsEditing(!isEditing)}
            disabled={hidden}
          >
            <IconPencil />
          </ActionIcon>
        </Group>
        <Paper p="lg" withBorder mih={rem(640)}>
          {isEditing ? (
            <form onSubmit={form.onSubmit(handleSave)}>
              <Textarea
                autosize
                minRows={6}
                {...form.getInputProps('coverLetter')}
                onBlur={() => form.validateField('coverLetter')}
              />
              <Group justify="flex-end" mt="md" gap="sm">
                <Button variant="outline" onClick={() => setIsEditing(false)}>
                  <IconX />
                  {t('labels.cancel')}
                </Button>
                <Button type="submit" disabled={!form.isValid()}>
                  <IconCheck />
                  {t('labels.save')}
                </Button>
              </Group>
            </form>
          ) : (
            <>
              {shownParagraphs.map((paragraph, idx) => (
                <CoverLetterParagraph
                  key={idx}
                  text={paragraph}
                  hidden={hidden}
                />
              ))}
              {hiddenParagraphs.length > 0 && (
                <div style={{ position: 'relative' }}>
                  {hiddenParagraphs.map((paragraph, idx) => (
                    <CoverLetterParagraph
                      key={idx}
                      text={paragraph}
                      hidden={hidden}
                    />
                  ))}
                  <SubscribeCTAOverlay enabled={hidden} />
                </div>
              )}
            </>
          )}
        </Paper>
      </Stack>
    </Container>
  );
};
