import { ActionIcon, Stack, Text, Tooltip } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconTrashX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React from 'react';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { IResume } from '../../models/Resume';
import { getLogger } from '../../services/Logger';

const log = getLogger('DeleteResumeButton');

export const DeleteResumeButton: React.FC<{
  resume: IResume;
  children?: React.ReactElement;
  beforeDelete?: () => void;
}> = ({ resume, children, beforeDelete }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (resumeId: IResume['id']) =>
      Api.ky.delete(`${ApiRoutes.Resumes}/${resumeId}`).json(),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['resumes'],
      }),
  });

  const onDelete = () =>
    openConfirmModal({
      title: 'Delete resume',
      centered: true,
      children: (
        <Stack my="lg">
          <div>
            <Text size="sm" fw={500}>
              {t('applications.deleteInfoTitle', { title: resume.title })}
            </Text>
            <Text size="sm">
              {t('applications.deleteInfoText', {
                created: dayjs(resume.createdAt).fromNow(),
              })}
            </Text>
          </div>
          <Text size="sm">{t('applications.deleteText')}</Text>
        </Stack>
      ),
      labels: {
        confirm: t('applications.deleteConfirm'),
        cancel: t('applications.deleteCancel'),
      },
      confirmProps: { color: 'red', leftSection: <IconTrashX /> },
      onConfirm: async () => {
        beforeDelete?.();
        try {
          await mutation.mutateAsync(resume.id);
        } catch (error) {
          log.error('Error while deleting resume', error);
        }
      },
    });

  return (
    <Tooltip label={t('applications.deleteTitle')}>
      {children ? (
        React.cloneElement(children, { onClick: onDelete })
      ) : (
        <ActionIcon variant="subtle" onClick={onDelete}>
          <IconTrashX />
        </ActionIcon>
      )}
    </Tooltip>
  );
};
