import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Container,
  Flex,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  Timeline,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { openConfirmModal } from '@mantine/modals';
import {
  IconCalendar,
  IconHash,
  IconNote,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IResume, IResumeHistory } from '../../models/Resume';
import {
  useAddHistoryMutation,
  useDeleteHistoryMutation,
  useResumeHistoryQuery,
} from '../../queries/ResumeHistoryQueries';

export const ResumeHistoryView: React.FC<{ resume: IResume }> = ({
  resume,
}) => {
  const { t } = useTranslation();

  const CreateHistorySchema = useMemo(
    () =>
      Yup.object().shape({
        date: Yup.date().required(t('errors.required')),
        tag: Yup.string().required(t('errors.required')),
        notes: Yup.string().nullable(),
      }),
    [t],
  );

  const defaultTags = useMemo(
    () =>
      ['note', 'applied', 'rejected', 'interview', 'offer'].map((tag) =>
        t(`view.historyTag.${tag}`),
      ),
    [t],
  );

  const [tags, setTags] = React.useState<string[]>([]);

  const { data } = useResumeHistoryQuery(resume.id, setTags, defaultTags);

  const addHistoryMutation = useAddHistoryMutation(resume.id);
  const deleteHistoryMutation = useDeleteHistoryMutation(resume.id);

  const confirmDeletion = (historyId: IResumeHistory['id']) =>
    openConfirmModal({
      title: t('view.confirmDeleteHistoryTitle'),
      children: <Text size="sm">{t('view.confirmDeleteHistoryText')}</Text>,
      labels: {
        confirm: t('labels.confirm'),
        cancel: t('labels.cancel'),
      },
      onConfirm: () => deleteHistoryMutation.mutateAsync(historyId),
    });

  const initialValues = () => ({
    date: new Date(),
    tag: '',
    notes: '',
  });

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: CreateHistorySchema,
    onSubmit: async (values, { setSubmitting, setStatus, setValues }) => {
      try {
        await addHistoryMutation.mutateAsync(values);
        await setValues(initialValues());
      } catch (error) {
        setStatus(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Container size="md">
      <Stack gap="xl">
        <Text color="dimmed">{t('view.historyDescription')}</Text>

        <Timeline
          active={(data?.history.length || 1) - 1}
          reverseActive
          bulletSize={24}
          lineWidth={2}
        >
          <Timeline.Item
            bullet={<IconNote size={12} />}
            title={<Title order={5}>{t('view.addNote')}</Title>}
          >
            <FormikProvider value={formik}>
              <Form>
                <Stack>
                  <Flex gap="md" wrap="wrap" rowGap={4}>
                    <DateInput
                      withAsterisk
                      label={t('view.historyDateLabel')}
                      leftSection={<IconCalendar />}
                      {...formik.getFieldProps('date')}
                      onChange={async (value) => {
                        await formik.setFieldValue('date', dayjs(value));
                      }}
                      maw={150}
                    />

                    <Autocomplete
                      withAsterisk
                      label={t('view.historyTagLabel')}
                      data={tags}
                      placeholder={t('view.historyTagPlaceholder')}
                      {...formik.getFieldProps('tag')}
                      onChange={async (value) => {
                        await formik.setFieldValue('tag', value);
                      }}
                      leftSection={<IconHash />}
                      radius="lg"
                      maw={200}
                    />

                    <TextInput
                      label={t('view.historyNotesLabel')}
                      placeholder={t('view.historyNotesPlaceholder')}
                      miw={300}
                      style={{ flexGrow: 2 }}
                      {...formik.getFieldProps('notes')}
                    />
                  </Flex>

                  <Button
                    type="submit"
                    loading={formik.isSubmitting}
                    disabled={!formik.isValid}
                    leftSection={<IconPlus />}
                  >
                    {t('labels.save')}
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          </Timeline.Item>

          {data?.history.map((h) => (
            <Timeline.Item
              key={h.id}
              bullet={<IconNote size={12} />}
              title={
                <Group justify="space-between">
                  <Group gap="md">
                    <Text fw={500}>{dayjs(h.date).format('LL')}</Text>
                    {h.tag && <Badge>{h.tag}</Badge>}
                  </Group>
                  <ActionIcon onClick={() => confirmDeletion(h.id)}>
                    <IconTrash size={16} />
                  </ActionIcon>
                </Group>
              }
            >
              {h.notes && (
                <Paper p="md" key={h.id}>
                  <Text>
                    <em>{h.notes}</em>
                  </Text>
                </Paper>
              )}
            </Timeline.Item>
          ))}
        </Timeline>
      </Stack>
    </Container>
  );
};
