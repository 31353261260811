import {
  Anchor,
  Box,
  Button,
  ColorInput,
  Container,
  Grid,
  Group,
  Image,
  Modal,
  Paper,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery, useToggle } from '@mantine/hooks';
import {
  Document,
  Page,
  PDFDownloadLink,
  PDFViewer,
  Text as PDFText,
} from '@react-pdf/renderer';
import { IconDownload, IconFiles, IconRotate2 } from '@tabler/icons-react';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { IResume, IResumeJSON } from '../../models/Resume';
import { IUser, SubscriptionType } from '../../models/User';

import { coverLetterTemplateBasic } from '../../pdf/CoverLetterBasicPDF/CoverLetterBasicPDF';
import { coverLetterTemplateCleanColumn } from '../../pdf/CoverLetterCleanColumnPDF/CoverLetterCleanColumnPDF';
import { coverLetterTemplateGraphic } from '../../pdf/CoverLetterGraphicPDF/CoverLetterGraphicPDF';
import { coverLetterTemplateSideBar } from '../../pdf/CoverLetterSideBarPDF/CoverLetterSideBarPDF';
import { coverLetterTemplateStylish } from '../../pdf/CoverLetterStylishPDF/CoverLetterStylishPDF';
import { PDFProps } from '../../pdf/PDFProps'; // Adjust the path as needed
import { resumeTemplateBasic } from '../../pdf/ResumeBasicPDF/ResumeBasicPDF';
import { resumeTemplateCleanColumn } from '../../pdf/ResumeCleanColumnPDF/ResumeCleanColumnPDF';
import { resumeTemplateGraphic } from '../../pdf/ResumeGraphicPDF/ResumeGraphicPDF';
import { resumeTemplateSideBar } from '../../pdf/ResumeSideBarPDF/ResumeSideBarPDF';
import {
  DEFAULT_HEADER_FONT,
  DEFAULT_TEXT_FONT,
  resumeTemplateStylish,
} from '../../pdf/ResumeStylishPDF/ResumeStylishPDF';
import FontSelector from './FontSelector';

interface TemplateEntry {
  label: string;
  component: React.FC<PDFProps>;
  preview?: string;
  previewFallback: string;
}

type DocumentType = 'resume' | 'coverLetter';

const resumeTemplates: TemplateEntry[] = [
  resumeTemplateBasic,
  resumeTemplateSideBar,
  resumeTemplateGraphic,
  resumeTemplateCleanColumn,
  resumeTemplateStylish,
];

const coverLetterTemplates: TemplateEntry[] = [
  coverLetterTemplateBasic,
  coverLetterTemplateSideBar,
  coverLetterTemplateGraphic,
  coverLetterTemplateCleanColumn,
  coverLetterTemplateStylish,
];

const pageSizes = [
  { label: 'A4', value: 'A4' },
  { label: 'Letter', value: 'Letter' },
  // Add more page sizes here if needed
];

export const ResumeDocumentView: React.FC<{
  resumeId: IResume['id'];
  resume: IResumeJSON;
  user: IUser;
  language: string;
  title?: string;
  coverLetter?: string; // Add cover letter prop
}> = ({ resumeId, resume, user, language, title }) => {
  const { t } = useTranslation();

  const [selectedTemplate, setSelectedTemplate] = useState<TemplateEntry>(
    resumeTemplates[0],
  );
  const [selectedCoverLetterTemplate, setSelectedCoverLetterTemplate] =
    useState<TemplateEntry>(coverLetterTemplates[0]);
  const [primaryColor, setPrimaryColor] = useState<string | undefined>(
    '#2F7D7A',
  );
  const [pageSize, setPageSize] = useState(pageSizes[0].value);
  const [headerFont, setHeaderFont] = useState(DEFAULT_HEADER_FONT);
  const [textFont, setTextFont] = useState(DEFAULT_TEXT_FONT);
  const [selectedFontCombination, setSelectedFontCombination] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [documentType, toggleDocumentType] = useToggle<DocumentType>([
    'resume',
    'coverLetter',
  ]);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const handleFontChange = (header: string, text: string, index: number) => {
    setHeaderFont(header);
    setTextFont(text);
    setSelectedFontCombination(index);
  };

  const handleToggleDocumentType = () => {
    toggleDocumentType();
  };

  const handleReset = () => {
    setSelectedTemplate(resumeTemplates[0]);
    setSelectedCoverLetterTemplate(coverLetterTemplates[0]);
    setPrimaryColor('#2F7D7A');
    setPageSize(pageSizes[0].value);
    setHeaderFont(DEFAULT_HEADER_FONT);
    setTextFont(DEFAULT_TEXT_FONT);
    setSelectedFontCombination(0);
  };

  const handleTemplateSelect = (template: TemplateEntry) => {
    if (documentType === 'coverLetter') {
      setSelectedCoverLetterTemplate(template);
    } else {
      setSelectedTemplate(template);
    }
    setModalOpened(false);
  };

  const templates =
    documentType === 'resume' ? resumeTemplates : coverLetterTemplates;

  const downloadFileName =
    (t(`download.${documentType}FileTitle`) as string) +
    (title ? ` - ${title}` : '') +
    '.pdf';

  const currentTemplate =
    documentType === 'resume' ? selectedTemplate : selectedCoverLetterTemplate;

  const templateDocument = (
    <Suspense
      fallback={
        <Document>
          <Page>
            <PDFText>{t('view.pdf.rendering')}</PDFText>
          </Page>
        </Document>
      }
    >
      <currentTemplate.component
        resume={resume}
        user={user}
        resumeId={resumeId}
        language={language}
        title={title}
        primaryColor={primaryColor}
        pageSize={pageSize}
        headerFont={headerFont}
        textFont={textFont}
      />
    </Suspense>
  );

  return (
    <Container fluid>
      <Grid mt="xl">
        <Grid.Col span={{ base: 12, sm: 12, md: 12, lg: 6 }}>
          <Stack gap="3rem">
            <Box>
              <Title order={2}>{t('view.pdf.title')}</Title>
              {user.subscriptionType === SubscriptionType.FREE && (
                <Anchor component={Link} c="dimmed" to={AppRouteURL.subscribe}>
                  {t('view.pdf.upgrade')}
                </Anchor>
              )}
            </Box>

            <Group justify="space-between">
              <SegmentedControl
                color="blue"
                radius="xl"
                value={documentType}
                onChange={handleToggleDocumentType}
                data={[
                  { label: t('view.resumeTab'), value: 'resume' },
                  { label: t('view.coverLetterTab'), value: 'coverLetter' },
                ]}
              />

              <PDFDownloadLink
                document={templateDocument}
                fileName={downloadFileName}
                style={{
                  textDecoration: 'none',
                }}
              >
                {({ loading }) => (
                  <Button leftSection={<IconDownload />}>
                    {loading
                      ? t('view.pdf.loadingButtonTitle')
                      : t('view.pdf.downloadButtonTitle')}
                  </Button>
                )}
              </PDFDownloadLink>
            </Group>
            <Paper withBorder p="sm">
              <Group justify="space-between">
                <Stack gap={0}>
                  <Title order={6} c="dimmed">
                    {t('view.pdf.selectedTemplateTitle')}
                  </Title>
                  <Text>{currentTemplate.label}</Text>
                </Stack>
                <Button
                  leftSection={<IconFiles />}
                  onClick={() => setModalOpened(true)}
                  variant="outline"
                >
                  {t('view.pdf.chooseTemplateButtonTitle')}
                </Button>
              </Group>
            </Paper>
            <FontSelector
              onFontChange={handleFontChange}
              selectedCombinationIndex={selectedFontCombination}
            />

            <Group justify="space-between">
              <Select
                label={
                  <Title order={6} c="dimmed">
                    {t('view.pdf.pageSizeLabel')}
                  </Title>
                }
                data={pageSizes}
                value={pageSize}
                onChange={(value) => setPageSize(value || pageSizes[0].value)}
              />
              <ColorInput
                label={
                  <Title order={6} c="dimmed">
                    {t('view.pdf.primaryColorLabel')}
                  </Title>
                }
                value={primaryColor}
                onChange={setPrimaryColor}
              />
            </Group>
            <Button
              leftSection={<IconRotate2 />}
              onClick={handleReset}
              variant="outline"
            >
              {t('view.pdf.resetButtonTitle')}
            </Button>
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ base: 12, sm: 12, md: 12, lg: 6 }}>
          <Box
            p="sm"
            h="100%"
            mih={690}
            style={{
              border: `1px solid ${theme.colors.gray[4]}`,
              borderRadius: theme.radius.md,
            }}
          >
            <PDFViewer width="100%" height="100%" showToolbar={true}>
              {templateDocument}
            </PDFViewer>
          </Box>
        </Grid.Col>
      </Grid>

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={
          <Text fz="lg" fw="bolder">
            {t('view.pdf.templateModalTitle')}
          </Text>
        }
        size={isMobile ? 'full' : 'lg'}
        fullScreen={isMobile}
      >
        <Grid>
          {templates.map((template) => (
            <Grid.Col key={template.label} span={6}>
              <Box
                style={{
                  border: `1px solid ${theme.colors.gray[4]}`,
                  borderRadius: theme.radius.md,
                  padding: theme.spacing.md,
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleTemplateSelect(template)}
              >
                <picture>
                  {template.preview && (
                    <source srcSet={template.preview} type="image/webp" />
                  )}
                  <Image
                    src={template.previewFallback}
                    alt={`${template.label} Preview`}
                    height={150}
                    fit="contain"
                  />
                </picture>
                <Text mt="sm" fw="bold">
                  {template.label}
                </Text>
              </Box>
            </Grid.Col>
          ))}
        </Grid>
      </Modal>
    </Container>
  );
};
