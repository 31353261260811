import {
  ActionIcon,
  Button,
  Group,
  List,
  Modal,
  Paper,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IResumeWithSuggestion } from '../../models/Resume';
import { useUpdateResumeJsonMutation } from '../../queries/ResumeQueries';

export const Skills: React.FC<{
  resume: IResumeWithSuggestion;
  hidden: boolean;
}> = ({ resume, hidden }) => {
  const skills = resume.suggestion.skills;
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const form = useForm({
    initialValues: {
      skills: skills.length > 0 ? skills : [''],
    },
    validate: {
      skills: (value) =>
        value.length === 0 || value.some((skill) => !skill)
          ? t('resume.skills.skillRequired')
          : null,
    },
  });

  const updateResumeMutation = useUpdateResumeJsonMutation(resume.id);

  const handleSave = () => {
    if (form.isValid()) {
      const updatedResumeJson = {
        ...resume.suggestion,
        skills: form.values.skills,
      };
      updateResumeMutation.mutate(updatedResumeJson, {
        onSuccess: () => {
          setOpened(false);
        },
      });
    }
  };

  const handleSkillChange = (index: number, value: string) => {
    form.setFieldValue(
      'skills',
      form.values.skills.map((skill, idx) => (idx === index ? value : skill)),
    );
    form.validateField('skills');
  };

  const addSkill = () => {
    form.setFieldValue('skills', [...form.values.skills, '']);
  };

  const removeSkill = (index: number) => {
    if (form.values.skills.length > 1) {
      form.setFieldValue(
        'skills',
        form.values.skills.filter((_, idx) => idx !== index),
      );
    }
  };

  return (
    <Stack>
      <Group>
        <Title order={3}>{t('resume.skills.title')}</Title>
        <ActionIcon
          variant="subtle"
          onClick={() => setOpened(true)}
          disabled={hidden}
          size="sm"
        >
          <IconPencil />
        </ActionIcon>
      </Group>

      <Paper p="md">
        <List>
          {skills.map((skill, idx) => (
            <List.Item key={idx}>{skill}</List.Item>
          ))}
        </List>
      </Paper>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('resume.skills.editSkills')}
        centered
        size="xl"
      >
        <form onSubmit={form.onSubmit(handleSave)}>
          <Stack gap="md">
            {form.values.skills.map((skill, index) => (
              <Group key={index} align="flex-start" mt="xs">
                <TextInput
                  value={skill}
                  onChange={(event) =>
                    handleSkillChange(index, event.currentTarget.value)
                  }
                  error={form.errors.skills}
                  onBlur={() => form.validateField(`skills.${index}`)}
                  style={{ flex: 1 }}
                />
                {form.values.skills.length > 1 && (
                  <ActionIcon color="red" onClick={() => removeSkill(index)}>
                    <IconTrash />
                  </ActionIcon>
                )}
              </Group>
            ))}
            <Button mt="md" leftSection={<IconPlus />} onClick={addSkill}>
              {t('resume.skills.addSkill')}
            </Button>
          </Stack>
          <Group justify="flex-end" mt="md" gap="sm">
            <Button variant="outline" onClick={() => setOpened(false)}>
              {t('labels.cancel')}
            </Button>
            <Button type="submit" disabled={!form.isValid()}>
              {t('labels.save')}
            </Button>
          </Group>
        </form>
      </Modal>
    </Stack>
  );
};
