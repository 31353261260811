import { Link, Styles, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { IUser } from '../../models/User';

export const ContactSection: React.FC<{ styles: Styles; user: IUser }> = ({
  styles,
  user,
}) => (
  <View style={styles.contactSection}>
    {user.profile?.displayEmail ? (
      <Text style={styles.contactText}>{user.profile?.displayEmail}</Text>
    ) : undefined}

    {user.profile?.phone ? (
      <Link
        src={`tel:${user.profile?.phone.replaceAll(' ', '')}`}
        style={styles.contactText}
      >
        {user.profile?.phone}
      </Link>
    ) : undefined}

    {user.profile?.address ? (
      <Text style={styles.contactText}>{user.profile?.address}</Text>
    ) : undefined}
  </View>
);
