import {
  ActionIcon,
  Center,
  Container,
  Group,
  Select,
  Stack,
  Tabs,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCaretLeft, IconCaretRight, IconPencil } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { CoverLetterView } from '../../components/CoverLetterView/CoverLetterView';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { ResumeDocumentView } from '../../components/ResumeDocumentView/ResumeDocumentView';
import { ResumeHistoryView } from '../../components/ResumeHistoryView/ResumeHistoryView';
import { ResumeInfoView } from '../../components/ResumeInfoView/ResumeInfoView';
import { ResumeView } from '../../components/ResumeView/ResumeView';
import { useProfileQuery } from '../../models/ProfileQueries';
import { IResumeWithSuggestion } from '../../models/Resume';
import { SubscriptionType, useUserQuery } from '../../models/User';
import { useResumeQuery } from '../../queries/ResumeQueries';
import { TitleModal } from './TitleModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const log = getLogger('ViewResumePage');

enum TABS {
  RESUME = 'resume',
  LETTER = 'letter',
  INFO = 'info',
  HISTORY = 'history',
  PDF = 'pdf',
}

const SuggestionNotFound = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title order={2}>{t('view.pageTitle')}</Title>
      <Text>{t('view.noResumeFound')}</Text>
    </Container>
  );
};

export const ViewResumePage: React.FC = () => {
  const { resumeId, tab } = useParams();
  const theme = useMantineTheme();
  const largeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
  const navigate = useNavigate();
  const [titleModalOpen, setTitleModalOpen] = useState(false);

  if (!resumeId) {
    return <SuggestionNotFound />;
  }

  const { data: user, isPending: userLoading } = useUserQuery();
  const { data: profile, isPending: profileLoading } = useProfileQuery();
  const { data: fullResume, isPending: resumeLoading } =
    useResumeQuery(resumeId);

  const isFreeUser = !user || user.subscriptionType === SubscriptionType.FREE;

  const { t } = useTranslation();

  const menuData = [
    { value: 'resume', label: t('view.resumeTab') },
    { value: 'letter', label: t('view.coverLetterTab') },
    { value: 'info', label: t('view.infoTab') },
    { value: 'history', label: t('view.historyTab') },
    { value: 'pdf', label: t('view.pdfTab') },
  ];

  if (
    (!user && userLoading) ||
    (!profile && profileLoading) ||
    (!fullResume && resumeLoading)
  ) {
    return <LoadingScreen />;
  }

  if (!fullResume || !user || !profile) {
    return <SuggestionNotFound />;
  }

  if (!fullResume.suggestion) {
    return (
      <Navigate
        to={`${AppRouteURL.resumes.create}/0?resumeId=${resumeId}`}
        replace={true}
      />
    );
  }

  user.profile = profile;

  const currentMenuEntry =
    menuData.find((entry) => entry.value === tab) || menuData[0];
  const currentMenuIndex =
    currentMenuEntry && menuData.indexOf(currentMenuEntry);
  const nextMenuEntry =
    currentMenuIndex === menuData.length - 1
      ? menuData[0]
      : menuData[currentMenuIndex + 1];
  const previousMenuEntry =
    currentMenuIndex === 0
      ? menuData[menuData.length - 1]
      : menuData[currentMenuIndex - 1];

  const suggestion = fullResume.suggestion;
  const resume = fullResume as IResumeWithSuggestion;

  return (
    <Container size="xl" p={largeScreen ? 'md' : 0} mb="xl">
      <Bowl title={t('view.pageTitle')} />

      {/*TODO: Rework tour for ViewResumePage*/}

      <Center>
        <Group>
          <ActionIcon
            variant="subtle"
            c="dimmed"
            mb="md"
            onClick={() =>
              navigate(
                `${AppRouteURL.resumes.view}/${resumeId}/${previousMenuEntry.value}`,
              )
            }
          >
            <IconCaretLeft />
          </ActionIcon>
          <Select
            maw="12rem"
            size="xs"
            mb="md"
            comboboxProps={{ shadow: 'md' }}
            data={menuData}
            value={tab || TABS.RESUME}
            onChange={(value) =>
              navigate(`${AppRouteURL.resumes.view}/${resumeId}/${value}`)
            }
            leftSectionPointerEvents="none"
          />
          <ActionIcon
            variant="subtle"
            c="dimmed"
            mb="md"
            onClick={() =>
              navigate(
                `${AppRouteURL.resumes.view}/${resumeId}/${nextMenuEntry.value}`,
              )
            }
          >
            <IconCaretRight />
          </ActionIcon>
        </Group>
      </Center>

      <Group justify="center" wrap="nowrap" gap="xs">
        <Title order={2} lineClamp={1} my="lg" ta="center">
          {fullResume.title || t('view.pageTitle')}
        </Title>
        <ActionIcon
          variant="subtle"
          size="sm"
          onClick={() => setTitleModalOpen(true)}
        >
          <IconPencil />
        </ActionIcon>
      </Group>

      <Stack gap="lg">
        <Tabs
          value={tab || TABS.RESUME}
          onChange={(value) =>
            navigate(`${AppRouteURL.resumes.view}/${resumeId}/${value}`)
          }
          orientation={largeScreen ? 'vertical' : 'horizontal'}
          radius="lg"
          keepMounted={false}
        >
          <Tabs.Panel value={TABS.RESUME} py="md">
            <ResumeView resume={resume} user={user} hidden={isFreeUser} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS.LETTER} py="md">
            <CoverLetterView resume={resume} hidden={isFreeUser} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS.INFO} py="md">
            <ResumeInfoView resume={fullResume} hidden={isFreeUser} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS.HISTORY} py="md">
            <ResumeHistoryView resume={fullResume} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS.PDF} py="md">
            <ResumeDocumentView
              resume={suggestion}
              user={user}
              language={fullResume.language as string}
              resumeId={fullResume.id}
              title={fullResume.title}
            />
          </Tabs.Panel>
        </Tabs>
      </Stack>

      {resume && (
        <TitleModal
          opened={titleModalOpen}
          onClose={() => setTitleModalOpen(false)}
          resume={resume}
        />
      )}
    </Container>
  );
};

export default ViewResumePage;
