import { Styles, Text, View } from '@react-pdf/renderer';
import React from 'react';

export const BulletPoints: React.FC<{ points: string[]; styles: Styles }> = ({
  points,
  styles,
}) =>
  Array.isArray(points) && (
    <View>
      {points.map((point, index) => (
        <View key={index} style={styles.bulletPointContainer}>
          <Text style={styles.bulletPointSymbol}>•</Text>
          <Text style={styles.bulletPointText}>{point}</Text>
        </View>
      ))}
    </View>
  );
