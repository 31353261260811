import { generateColors } from '@mantine/colors-generator';
import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import '../../util/dayjs-i18n';
import React, { useMemo } from 'react';

import {
  CoverLetterPDF,
  createCoverLetterStyles,
  currentDateString,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
} from '../CoverLetterPDF/CoverLetterPDF';
import { PDFProps, TemplateType } from '../PDFProps';

import previewPng from '../ResumeStylishPDF/preview-stylish.png';
import previewWebP from '../ResumeStylishPDF/preview-stylish.webp';

const specificCoverLetterStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
) => {
  const colors = generateColors(primaryColor);
  return StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FAFAFA',
      padding: 32,
      fontSize: 12,
      fontFamily: textFont,
    },
    header: {
      marginBottom: 20,
    },
    headerText: {
      fontSize: 24,
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: primaryColor,
      textAlign: 'center',
    },
    contactSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: 10,
      paddingVertical: 10,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderTopColor: colors[6],
      borderBottomColor: colors[6],
    },
    contactLeft: {
      width: '70%',
    },
    contactRight: {
      width: '30%',
      textAlign: 'right',
    },
    contactText: {
      fontSize: 10,
      fontFamily: textFont,
      color: '#333',
      marginBottom: 5,
    },
    link: {
      fontSize: 10,
      fontFamily: textFont,
      color: colors[6],
      textDecoration: 'none',
      marginBottom: 5,
    },
    specificHeader: {
      fontSize: 20,
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: primaryColor,
      marginBottom: 10,
      textAlign: 'center',
    },
    subHeader: {
      fontSize: 12,
      fontFamily: headerFont,
      color: '#555',
      marginBottom: 32,
      textAlign: 'center',
    },
    dateSection: {
      marginBottom: 32,
      textAlign: 'right',
    },
    container: {
      padding: 10,
    },
  });
};

export const CoverLetterStylishPDF: React.FC<PDFProps> = ({
  user,
  resume,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const styles = useMemo(
    () => ({
      ...createCoverLetterStyles(
        primaryColor || '#2F7D7A',
        headerFont,
        textFont,
      ),
      ...specificCoverLetterStyles(
        primaryColor || '#2F7D7A',
        headerFont,
        textFont,
      ),
    }),
    [primaryColor, headerFont, textFont],
  );

  const paragraphs = resume.coverLetter?.split('\n\n') || [];

  const date = currentDateString(language);

  return (
    <CoverLetterPDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <View style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerText}>
            {user.profile?.firstName} {user.profile?.lastName}
          </Text>
          <Text style={styles.subHeader}>{title}</Text>
        </View>
        <View style={styles.contactSection}>
          <View style={styles.contactLeft}>
            <Text style={styles.contactText}>{user.profile?.phone}</Text>
            <Text style={styles.contactText}>{user.profile?.address}</Text>
            <Link
              src={`mailto:${user.profile?.displayEmail}`}
              style={styles.link}
            >
              {user.profile?.displayEmail}
            </Link>
            <Link src={user.profile?.website} style={styles.link}>
              {user.profile?.website}
            </Link>
          </View>
          <View style={styles.contactRight}>
            <Text style={styles.contactText}>{date}</Text>
          </View>
        </View>
        {paragraphs.map((paragraph, idx) => (
          <View key={idx} style={styles.contentSection}>
            <Text style={styles.text}>{paragraph}</Text>
          </View>
        ))}
      </View>
    </CoverLetterPDF>
  );
};

export const coverLetterTemplateStylish: TemplateType = {
  label: 'Stylish',
  component: CoverLetterStylishPDF,
  preview: previewWebP,
  previewFallback: previewPng,
};
