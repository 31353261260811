import { generateColors } from '@mantine/colors-generator';
import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PDFProps, TemplateType } from '../PDFProps';
import {
  BulletPoints,
  createStyles,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
  ResumePDF,
} from '../ResumePDF';

import previewPng from './preview-clean-column.png';
import previewWebP from './preview-clean-column.webp';

const specificStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
) =>
  StyleSheet.create({
    specificHeader: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: '#333',
      marginBottom: 10,
    },
    subHeader: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: headerFont,
      color: '#555',
      marginBottom: 20,
    },
    sectionTitle: {
      fontSize: 12,
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: '#333',
      borderBottomWidth: 1,
      borderBottomColor: '#E4E4E4',
      marginBottom: 10,
      paddingBottom: 5,
    },
    sidebar: {
      flexDirection: 'column',
      width: '30%',
      paddingRight: 20,
      borderRightWidth: 1,
      borderRightColor: '#E4E4E4',
      marginRight: 20,
    },
    content: {
      flexDirection: 'column',
      width: '70%',
    },
    text: {
      fontSize: 9,
      color: '#666',
      marginBottom: 5,
      fontFamily: textFont,
      textDecoration: 'none',
    },
    bulletPointContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginBottom: 3,
    },
    bulletPointSymbol: {
      width: 10,
      fontSize: 10,
      marginRight: 5,
      marginTop: 2,
      color: '#333',
    },
    bulletPointText: {
      flex: 1,
      fontSize: 9,
      color: '#666',
    },
    personalInfo: {
      marginBottom: 20,
    },
    workExperience: {
      marginBottom: 20,
    },
    education: {
      marginBottom: 20,
    },
    skills: {
      marginBottom: 20,
    },
    languages: {
      marginBottom: 20,
    },
    section: {
      marginBottom: 10,
      paddingBottom: 0,
    },
    shadedBox: {
      padding: 10,
      borderRadius: 5,
      backgroundColor: generateColors(primaryColor)[1],
      marginBottom: 10,
    },
    contact: {
      marginBottom: 20,
    },
    companySubTitle: {
      fontSize: 10,
      color: '#666',
      fontFamily: headerFont,
      marginTop: 2,
      marginBottom: 2,
    },
    experienceDates: {
      color: '#666',
      fontFamily: textFont,
      fontSize: 10,
      marginBottom: 8,
    },
  });

export const ResumeCleanColumnPDF: React.FC<PDFProps> = ({
  resume,
  user,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const styles = useMemo(
    () => ({
      ...createStyles(primaryColor || '#2F7D7A', headerFont, textFont),
      ...specificStyles(primaryColor || '#2F7D7A', headerFont, textFont),
    }),
    [primaryColor, headerFont, textFont],
  );
  const { t } = useTranslation(undefined, { lng: language });

  return (
    <ResumePDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <Text style={styles.specificHeader}>
        {user.profile?.firstName} {user.profile?.lastName}
      </Text>
      <Text style={styles.subHeader}>{title}</Text>

      <View style={{ flexDirection: 'row' }}>
        <View style={styles.sidebar}>
          <View style={styles.contact}>
            <Text style={styles.sectionTitle}>{t('resume.contact')}</Text>
            <View style={styles.shadedBox}>
              <Text style={styles.text}>{user.profile?.phone}</Text>
              <Text style={styles.text}>{user.profile?.address}</Text>
              <Link
                src={`mailto:${user.profile?.displayEmail}`}
                style={styles.text}
              >
                {user.profile?.displayEmail}
              </Link>
              <Link src={user.profile?.website} style={styles.text}>
                {user.profile?.website}
              </Link>
            </View>
          </View>

          <View style={styles.skills}>
            <Text style={styles.sectionTitle}>{t('resume.skills.title')}</Text>
            <View style={styles.shadedBox}>
              <BulletPoints points={resume.skills} styles={styles} />
            </View>
          </View>

          {Array.isArray(resume.educations) && resume.educations.length > 0 && (
            <View style={styles.education}>
              <Text style={styles.sectionTitle}>
                {t('resume.education.title')}
              </Text>
              {resume.educations.map((edu, index) => (
                <View key={index} style={styles.shadedBox}>
                  <Text style={styles.text}>
                    {edu.degree}, {edu.fieldOfStudy}
                  </Text>
                  <Text style={styles.text}>{edu.schoolName}</Text>
                  <Text style={styles.text}>
                    {edu.start} - {edu.end}
                  </Text>
                  <BulletPoints points={edu.notes} styles={styles} />
                </View>
              ))}
            </View>
          )}

          {user.profile?.languages && user.profile.languages.length > 0 && (
            <View style={styles.languages} wrap={false}>
              <Text style={styles.sectionTitle}>{t('resume.languages')}</Text>
              <View style={styles.shadedBox}>
                <BulletPoints
                  points={user.profile.languages.map((lang) => lang.name)}
                  styles={styles}
                />
              </View>
            </View>
          )}
        </View>

        <View style={styles.content}>
          <View style={styles.personalInfo}>
            <Text style={styles.sectionTitle}>
              {t('resume.personalInformation')}
            </Text>
            <View style={styles.shadedBox}>
              <Text style={styles.text}>{resume.intro}</Text>
            </View>
          </View>

          {Array.isArray(resume.experiences) &&
            resume.experiences.length > 0 && (
              <View style={styles.workExperience}>
                <Text style={styles.sectionTitle}>
                  {t('resume.experience.title')}
                </Text>
                {resume.experiences.map((job, index) => (
                  <View key={index} style={styles.section} wrap={false}>
                    <View style={styles.shadedBox}>
                      <Text
                        style={{ fontFamily: headerFont, fontWeight: 'bold' }}
                      >
                        {job.title}
                      </Text>
                      <Text style={styles.companySubTitle}>
                        {job.company.name}{' '}
                        {job.company.location && ` (${job.company.location})`}
                      </Text>
                      <Text style={styles.experienceDates}>
                        {job.start} - {job.end || t('timeline.present')}
                      </Text>

                      <BulletPoints points={job.summary} styles={styles} />
                    </View>
                  </View>
                ))}
              </View>
            )}
        </View>
      </View>
    </ResumePDF>
  );
};

export const resumeTemplateCleanColumn: TemplateType = {
  label: 'Clean Column',
  component: ResumeCleanColumnPDF,
  preview: previewWebP,
  previewFallback: previewPng,
};
